<!--
 * @Author: 刘格优
 * @Date: 2020-05-09 11:05:47
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-05-09 11:07:55
 -->
<!--  -->
<template>
  <div>
    <img src="@/assets/nh/newhdgz.jpg" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
<style scoped></style>
